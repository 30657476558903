.product-carousel-div {
    display: flex;
    justify-content: center;
  
    .product-carousel-inner-div {
      width: 100%;
    }

    @include mq('laptop') {
      .product-carousel-inner-div {
        width: 95%;
      }
    }

    .react-multi-carousel-list {
        position: inherit;
    }
}

.product-div {
    padding: 65px 0px;

    .products-header {
      justify-content: space-between;

      a {
        color: inherit;
        margin-left: 0.8rem;
        margin-top: 1rem;
        margin-right: 0.5rem;

        @include mq('laptop') {
          margin-right: 2rem;
        }
        
        svg {
          margin-top: 0.1rem;
        }

        &:hover {
          text-decoration: none;
          color: $red-desert;
        }
      }
    }

    h3 {
      margin-left: 15px;
    }

    @include mq('laptop') {
        padding: 65px 45px;

        h3 {
          margin-left: 0px;
        }
    }

    .carousel-arrow {
        display: none;

        @include mq('laptop') {
            background: none;
            display: block;
            position: absolute;
            outline: 0;
            transition: all .5s;
            border-radius: 35px;
            z-index: 998;
            border: 0;
            min-width: 43px;
            min-height: 43px;
            opacity: 1;
            cursor: pointer;
        }
    }
    
    .carousel-arrow.right {
        right: 30px;
    }

    .carousel-arrow.left {
        left: 30px;
    }
}

.product-div.gray {
    background-color: $gray9;
}

.product-div.petralight {
    color: $petra;
    background-color: $petra-light;
}

.carousel-img {
    height: 230px;
}
