@import "../../assets/styles/variables";
.custom-range::-webkit-slider-thumb {
  background: gray;
}

.custom-range::-moz-range-thumb {
  background: gray;
}

.custom-range::-ms-thumb {
  background: gray;
}

.filter-header {
  border-bottom: 1px solid $gray6;
  padding-bottom: 10px;
  
  h4 {
    font-weight: 600;
    margin: 0;
  }

  .btn-link {
    color: $smoke;
    font-size: 14px;
  }
}

.filter-accordion {
  border-bottom: 1px solid $gray6;

  .collapse {
    padding-bottom: 10px;
  
    &.show::before {
      display: none;
    }
  }

  .label2 {
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .form-control-sm {
    font-size: 11px;
  }
}

.filter-toggle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  color: $obsidian;
  font-size: 12px;
  border-radius: 0;
  font-weight: 600;

  &:hover {
    color: $obsidian;
    text-decoration: none;
    background-color: rgba($obsidian, .03);
  }

  &:focus {
    text-decoration: none;
  }
}

.filter-item-custom {
  label {
    font-size: 12px;
    line-height: 200%;
  }

  .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: $petra;
    background-color: $petra;
  }

  .custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: $red-desert;
  }

  .custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba($petra,.25);
  }
}

.link-accordion-toggle {
  align-items: center;
  padding: 4px 0;
  color: $petra;
  font-size: 12px;
  border-radius: 0;
  font-weight: 400;

  &:hover {
    color: $petra;
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
}