@import "assets/styles/variables";
@import "assets/styles/mixins";
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600&display=swap");

// @include font(Trade Gothic LT Std Cn, assets/fonts/TradeGothicLTStd-Cn18);
// @include font(Blair ITC Light, assets/fonts/BlairITC-Light);
// @include font(Blair ITC Medium, assets/fonts/BlairITC-Medium);
// @include font(Blair ITC Bold, assets/fonts/BlairITC-Bold);
// @include font(BodoniStd Bold, assets/fonts/BodoniStd-Bold);

@font-face {
  font-family: 'Trade Gothic LT Std Cn';
  src: url(assets/fonts/TradeGothicLTStd-Cn18.ttf);
}
@font-face {
  font-family: 'Blair ITC Light';
  src: url(assets/fonts/BlairITC-Light.ttf);
}
@font-face {
  font-family: 'Blair ITC Medium';
  src: url(assets/fonts/BlairITC-Medium.ttf);
}
@font-face {
  font-family: 'Blair ITC Bold';
  src: url(assets/fonts/BlairITC-Bold.ttf);
}
@font-face {
  font-family: 'BodoniStd Bold';
  src: url(assets/fonts/BodoniStd-Bold.ttf);
}


html {
  overflow-x: hidden;
}

body {
  background-color: #ffffff;
  font-family: $font-montserrat;
  overflow-x: hidden;
  color: $obsidian;
}

img {
  max-width: 100%;
}

//Padding and margin helpers

.p-10 {
  padding: 10%;
}

.p-r-28 {
  padding-right: 28px;
}

.p-r-55 {
  padding-right: 55px;
}

.p-lr-90 {
  @include mq("laptop") {
    padding-left: 90px;
    padding-right: 90px;
  }

  @include mq("desktop") {
    padding-left: 90px;
    padding-right: 90px;
  }

  @include mq("desktop-wide") {
    padding-left: 90px;
    padding-right: 90px;
  }
}

.p-12-30 {
  padding: 12px 30px;
}

.p-15-30 {
  padding: 15px 30px;
}

.p-13-45 {
  @include mq("phone-small") {
    padding: 6px 30px;
  }

  @include mq("phone") {
    padding: 6px 30px;
  }

  @include mq("phone-wide") {
    padding: 6px 30px;
  }

  @include mq("phablet") {
    padding: 6px 30px;
  }

  @include mq("tablet-small") {
    padding: 6px 30px;
  }

  @include mq("tablet") {
    padding: 6px 30px;
  }

  @include mq("laptop") {
    padding: 6px 30px;
  }

  @include mq("desktop") {
    padding: 13px 45px;
  }

  @include mq("desktop-wide") {
    padding: 13px 45px;
  }
}

.p-13-55 {
  padding: 13px 55px;
}

.p-16-50 {
  @include mq("phone-small") {
    padding: 8px 25px;
  }

  @include mq("phone") {
    padding: 8px 25px;
  }

  @include mq("phone-wide") {
    padding: 8px 25px;
  }

  @include mq("phablet") {
    padding: 8px 25px;
  }

  @include mq("tablet-small") {
    padding: 8px 25px;
  }

  @include mq("tablet") {
    padding: 8px 25px;
  }

  @include mq("laptop") {
    padding: 8px 25px;
  }

  @include mq("desktop") {
    padding: 16px 50px;
  }

  @include mq("desktop-wide") {
    padding: 16px 50px;
  }
}

.p-20-135 {
  padding: 13px 55px;
}

.smmt-20 {
  @media (min-width: 360px) and (max-width: 769px) {
    margin-top: 20px;
  }
}

.smmt-40 {
  @media (min-width: 360px) and (max-width: 769px) {
    margin-top: 40px;
  }
}

.smmt-70 {
  @media (min-width: 360px) and (max-width: 769px) {
    margin-top: 70px;
  }
}

.smmb-20 {
  @media (min-width: 360px) and (max-width: 769px) {
    margin-bottom: 20px;
  }
}

.smmb-40 {
  @media (min-width: 360px) and (max-width: 769px) {
    margin-bottom: 40px;
  }
}

.smmb-70 {
  @media (min-width: 360px) and (max-width: 769px) {
    margin-bottom: 70px;
  }
}

.m-10 {
  margin: 10px;
}

.mt-n-5 {
  margin-top: -5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-64 {
  margin-top: 64px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-180 {
  margin-top: 180px;
}

.mbn-10 {
  margin-bottom: -10px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-120 {
  margin-bottom: 120px;
}

.ml-44 {
  margin-left: 44px;
}

.mtb-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mtb-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mtb-70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.mtb-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mtb-120 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.ptb-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-30 {
  margin-right: 30px;
}

.mdmr-0 {
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin-right: 0px !important;
  }
}

.mdmr-10 {
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin-right: 10px !important;
  }
}

.plr-70 {
  padding: 0px 70px;
}

// Font helpers

.label2 {
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.page-title {
  font-family: $font-title;
  color: #000000;
}

.btntext-13-sb {
  @include mq("phone-small") {
    font-size: 10px;
    font-weight: 600;
  }

  @include mq("phone") {
    font-size: 10px;
    font-weight: 600;
  }

  @include mq("phone-wide") {
    font-size: 10px;
    font-weight: 600;
  }

  @include mq("phablet") {
    font-size: 10px;
    font-weight: 600;
  }

  @include mq("tablet-small") {
    font-size: 10px;
    font-weight: 600;
  }

  @include mq("tablet") {
    font-size: 10px;
    font-weight: 600;
  }

  @include mq("laptop") {
    font-size: 10px;
    font-weight: 600;
  }

  @include mq("desktop") {
    font-size: 13px;
    font-weight: 600;
  }

  @include mq("desktop-wide") {
    font-size: 13px;
    font-weight: 600;
  }
}

a {
  &.btntext-13-sb {
    &:hover {
      text-decoration: none;
    }
  }
}

.btntext-18-sb {
  @include mq("phone-small") {
    font-size: 12px !important;
    font-weight: 600;
    float: left;
  }

  @include mq("phone") {
    font-size: 12px !important;
    font-weight: 600;
    float: left;
  }

  @include mq("phone-wide") {
    font-size: 12px !important;
    font-weight: 600;
    float: left;
  }

  @include mq("phablet") {
    font-size: 12px !important;
    font-weight: 600;
    float: left;
  }

  @include mq("tablet-small") {
    font-size: 12px !important;
    font-weight: 600;
    float: left;
  }

  @include mq("tablet") {
    font-size: 12px !important;
    font-weight: 600;
    float: left;
  }

  @include mq("laptop") {
    font-size: 14px !important;
    font-weight: 600;
  }

  @include mq("desktop") {
    font-size: 18px !important;
    font-weight: 600;
  }

  @include mq("desktop-wide") {
    font-size: 18px !important;
    font-weight: 600;
  }
}

.btn-padding {
  padding: 13px 45px;
}

.body-1-m {
  font-family: $font-montserrat;
  font-weight: 500;
  line-height: 170%;
  font-size: 14px;
}

.body-2-m {
  font-family: $font-montserrat;
  font-weight: 500;
  line-height: 180%;
  font-size: 16px;
}

.body-1-reg {
  font-family: $font-montserrat;
  color: $obsidian;
  font-weight: 400;
  line-height: 180%;
  @include mq("phone-small") {
    font-size: 12px;
  }

  @include mq("phone") {
    font-size: 12px;
  }

  @include mq("phone-wide") {
    font-size: 12px;
  }

  @include mq("phablet") {
    font-size: 12px;
  }

  @include mq("tablet-small") {
    font-size: 12px;
  }

  @include mq("tablet") {
    font-size: 12px;
  }

  @include mq("laptop") {
    font-size: 12px;
  }

  @include mq("desktop") {
    font-size: 14px;
  }

  @include mq("desktop-wide") {
    font-size: 14px;
  }
}

.body-2-reg {
  font-family: $font-montserrat;
  color: $obsidian;
  font-weight: 400;
  line-height: 180%;
  @include mq("phone-small") {
    font-size: 14px;
  }

  @include mq("phone") {
    font-size: 14px;
  }

  @include mq("phone-wide") {
    font-size: 14px;
  }

  @include mq("phablet") {
    font-size: 14px;
  }

  @include mq("tablet-small") {
    font-size: 14px;
  }

  @include mq("tablet") {
    font-size: 14px;
  }

  @include mq("laptop") {
    font-size: 16px;
  }

  @include mq("desktop") {
    font-size: 16px;
  }

  @include mq("desktop-wide") {
    font-size: 16px;
  }
}

.body-3-reg {
  font-weight: 600;
  line-height: 180%;
  @include mq("phone-small") {
    font-size: 34px;
  }

  @include mq("phone") {
    font-size: 34px;
  }

  @include mq("phone-wide") {
    font-size: 34px;
  }

  @include mq("phablet") {
    font-size: 34px;
  }

  @include mq("tablet-small") {
    font-size: 34px;
  }

  @include mq("tablet") {
    font-size: 34px;
  }

  @include mq("laptop") {
    font-size: 34px;
  }

  @include mq("desktop") {
    font-size: 36px;
  }

  @include mq("desktop-wide") {
    font-size: 36px;
  }
}

.body-1-sb {
  font-size: 14px;
  font-weight: 600;
  line-height: 180%;
}

.body-1-sb-uc {
  font-size: 14px;
  font-weight: 600;
  line-height: 180%;
  text-transform: uppercase;
}

.body-2-sb {
  font-size: 16px;
  font-weight: 600;
  line-height: 180%;
}

.body-3-sb {
  font-size: 24px;
  font-weight: 600;
  line-height: 180%;
}

.body-18-sb {
  font-size: 18px;
  font-weight: 600;
  line-height: 170%;
}

h1,
h2,
h3,
h4 {
  font-family: $font-title;
}

h1 {
  @include mq("phone-small") {
    font-size: 40px;
    line-height: 110%;
  }

  @include mq("phone") {
    font-size: 40px;
    line-height: 110%;
  }

  @include mq("phone-wide") {
    font-size: 40px;
    line-height: 110%;
  }

  @include mq("phablet") {
    font-size: 40px;
    line-height: 110%;
  }

  @include mq("tablet-small") {
    font-size: 40px;
    line-height: 110%;
  }

  @include mq("tablet") {
    font-size: 40px;
    line-height: 110%;
  }

  @include mq("laptop") {
    font-size: 48px;
    line-height: 110%;
  }

  @include mq("desktop") {
    font-size: 80px;
    line-height: 110%;
  }

  @include mq("desktop-wide") {
    font-size: 80px;
    line-height: 110%;
  }
}

h2 {
  @include mq("phone-small") {
    font-size: 32px;
    line-height: 120%;
  }

  @include mq("phone") {
    font-size: 32px;
    line-height: 120%;
  }

  @include mq("phone-wide") {
    font-size: 32px;
    line-height: 120%;
  }

  @include mq("phablet") {
    font-size: 32px;
    line-height: 120%;
  }

  @include mq("tablet-small") {
    font-size: 32px;
    line-height: 120%;
  }

  @include mq("tablet") {
    font-size: 48px;
    line-height: 120%;
    font-weight: bold;
  }
}

h3 {
  font-size: 36px;
  line-height: 120%;
  letter-spacing: -0.5px;
  font-weight: 600;
}

.obsidian-font {
  color: $obsidian;
}

.obsidian-5-font {
  color: #{$obsidian + "40"};
}

.petra-font {
  color: $petra !important;
}

.copper-font {
  color: $copper;
}

.smoke-font {
  color: $smoke !important;
}

.white-font {
  color: white;
}

.link-hover-copper:hover {
  color: $copper;
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.display-none {
  display: none;
}

.hide-block {
  display: none;
  @include mq("laptop") {
    display: block;
  }

  @include mq("desktop") {
    display: block;
  }

  @include mq("desktop-wide") {
    display: block;
  }
}

.show-block {
  display: block;
  @include mq("laptop") {
    display: none;
  }

  @include mq("desktop") {
    display: none;
  }

  @include mq("desktop-wide") {
    display: none;
  }
}

//buttons
.btn {
  border-radius: 100px;
  font-size: 13px;
  border: 0;
}

.btn-dark {
  border-radius: 100px;
  background-color: $obsidian;
  font-size: 13px;
  border: 0;
}

.btn-petra {
  @extend .btn;
  background-color: $petra;

  &.pricing-component {
    box-shadow: 0px 3px 9px rgba(51, 51, 43, 0.14);
  }

  &:focus,
  &:active,
  &.btn-primary:not(:disabled):not(.disabled).active,
  &.btn-primary:not(:disabled):not(.disabled):active {
    background-color: $petra;
    border: 1px solid $copper;
    color: #ffffff;
    box-shadow: 0 0 0 0.2rem rgba($petra, 0.5);
  }
}

.btn-outline-petra {
  border-radius: 100px;
  background-color: #ffffff;
  font-size: 13px;
  border: 1px solid $petra;
  color: $petra;

  &:hover,
  &:focus,
  &:active,
  &.btn-primary:not(:disabled):not(.disabled).active,
  &.btn-primary:not(:disabled):not(.disabled):active,
  &.btn-primary:disabled {
    background-color: $petra;
    font-size: 13px;
    border: 1px solid $petra;
    color: #ffffff;
  }

  &:focus,
  &:active,
  &.btn-primary:not(:disabled):not(.disabled).active,
  &.btn-primary:not(:disabled):not(.disabled):active {
    box-shadow: 0 0 0 0.2rem rgba($petra, 0.5);
  }
}

.btn-primary.disabled, .btn-primary:disabled {
  cursor: not-allowed;
  color: #fff;
  background-color: $copper;
  border-color: $copper;
  opacity: .65;
}

.btn-white {
  border-radius: 100px;
  background-color: white;
  font-size: 13px;
  border: none;
}

.btn-white:hover {
  background-color: $obsidian;
  color: white;
}

.btn-black {
  border-radius: 100px;
  background-color: $obsidian;
  color: white;
  font-size: 13px;
  border: none;

  &:hover {
    background-color: $vulcanite;
    color: white;
  }
}

.btn-petra:hover {
  background-color: $copper;
}

.btn-copper {
  border-radius: 100px;
  background-color: $copper;
  border: none;
}

.btn-copper:hover {
  background-color: $petra;
}

.caption1 {
  font-size: 11px;
  font-weight: 100;
  color: $vulcanite;
  text-transform: uppercase;
}

.caption2 {
  font-size: 14px;
  font-weight: 100;
  color: $vulcanite;
  font-family: $font-montserrat;
}

.projects-list {
  list-style-image: url("./assets/img/check.png");
}

.smoke-5-bg {
  background-color: #{$smoke + "05"};
}

.container-wide {
  margin-right: auto;
  margin-left: auto;
}

@include mq("desktop-wide") {
  .container-wide {
    max-width: 1420px;
  }
}

.text-shadow {
  text-shadow: #00000030 3px 3px 3px;
}

.sq-center {
  @include mq("tablet", "min") {
    height: 500px;
  }
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.no-focus-outline a:focus,
.no-focus-outline button:focus,
.no-focus-outline input:focus {
  outline: none;
  box-shadow: none;
}

.f-cormorant {
  font-family: $font-cormorant !important;
}

.f-bodoni {
  font-family: $font-bodoni !important;
}

.width-fit-content {
  width: fit-content;
}

.f60 {
  @media only screen and (min-width: 425px) {
    font-size: 50px;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 60px;
  }
}

.f45 {
  @media only screen and (min-width: 425px) {
    font-size: 40px;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 45px;
  }
}

.f40 {
  @media only screen and (min-width: 425px) {
    font-size: 32px;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 40px;
  }
}

.f24 {
  font-size: 24px;
}

.f20 {
  font-size: 20px;
}

.f16 {
  font-size: 16px;
}

.f14 {
  font-size: 14px;
}

.swal-title {
  font-size: 1.675em !important;
}

.btn:active:not(.btn-white, .btn-back, .btn-link, .dropdown-toggle) {
  background-color: $petra !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.btn-white:active {
  background-color: $obsidian !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.loading-div {
  height: 80vh;
}

.loading-img {
  width: 75px;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.align-super {
  vertical-align: super;
}