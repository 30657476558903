@import '../../../assets/styles/variables';

.steps-header {
  margin-bottom: 20px;
}

.steps-icon {
  height: 75px;
  margin: 30px 0px;
}
