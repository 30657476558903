@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';
@import '../../../assets/styles/helpers';

.btn-back {
  padding: 6px 0;
  color: $smoke;
  font-size: 12px;
  margin-bottom: 4px;

  &:hover {
    text-decoration: none;
    color: $smoke;
  }
}

.product-detail-title {
  font-weight: 700;
  font-size: 32px;
}

.product-image-zoom {
  .iiz__img {
    max-height: 400px;
  }
}

.product-merchant-logo {
  max-width: 180px;
  width: auto;
  max-height: 30px;
}

.increment-input.input-group {
  width: 150px;

  .form-control {
    border-radius: 4px;
  }

  .btn-link {
    color: $smoke;
  }
}

.out-of-stock {
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: #d0765b;
  margin-left: 10px;
}

.price-container {
  display: flex;
  flex-direction: row;
}

// hide arrows in number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.product-image-container {
  position: relative;
}

.product-image {
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px 10px;

  &.active {
    border: 1px solid #d0765b !important;
  }
}

.images-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.original-price {
  color: #d0765b;
  margin-right: 8px;
  text-decoration: line-through;
}

.discount {
  color: #d0765b;
}

.stocks-remaining-label {
  font-size: .75rem;
  color: $smoke;
}
