@import '../../assets/styles/mixins';

.stick-top {
  position: sticky;
  top: 100px;
}

@include mq('tablet') {
  .stick-md-top {
    position: sticky;
    top: 100px;
  }
}

