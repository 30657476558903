@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.featured-preview-badge {
  &::before {
    position: absolute;
    text-align: left;
    left: 30px;
    bottom: 30px;
    font-size: 25px;
    color: white;
    z-index: 2;
  }
  &[preview-title] {
    &::before {
      content: attr(preview-title);
      font-weight: bold;
    }

    &::after {
      position: absolute;
      text-align: left;
      left: 30px;
      bottom: 30px;
      font-size: 25px;
      color: white;
    }

    &:hover .preview-image{
      filter: brightness(50%);
    }
  }
}

.square-bg {
  position: relative;

  &:nth-child(even) {
    background-color: $petra-light;
    padding-bottom: 0;

    @include mq('tablet') {
      background: none;
      padding-bottom: 3rem;
    }

    &:before {
      background: none;
      content: '';
      height: 600px;
      position: absolute;
      right: -15%;
      top: 50px;
      z-index: -1;

      @include mq('tablet') {
        background-color: $petra-light;
        width: 400px;
        height: 300px;
        width: 500px;
        top: 130px;
      }

      @include mq('laptop') {
        height: 400px;
        width: 400px;
      }

      @include mq('desktop') {
        height: 500px;
        width: 500px;
      }
    }
  }

  &:nth-child(odd) {
    &:after {
      background: none;
      content: '';
      height: 600px;
      position: absolute;
      left: -15%;
      top: -20px;
      z-index: -1;

      @include mq('tablet') {
        background-color: $petra-light;
        width: 400px;
        height: 300px;
        width: 500px;
        top: unset;
        bottom: 23%;
      }

      @include mq('laptop') {
        height: 400px;
        width: 700px;
        bottom: 28%;
      }

      @include mq('desktop') {
        height: 500px;
        width: 500px;
        bottom: 27%;
      }
    }
  }
}

.preview-image {
  height: 220px;
  width: 100%;
  object-fit: cover;
  align-items: center;

  &:hover {
    filter: brightness(50%);
  }
}

.preview-row {
  align-items: center;
}

.featured-projects-container {
  margin-left: 50px;
  margin-right: 50px;
}
