@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';
@import '../../assets/styles/helpers';

.about-empire-section {
  background-color: #f9f4f0;
}

.about-hr {
  border-color: $petra;
  width: 100px;
}

.about-img {
  width: 100%;
  object-fit: contain;
}

.who-is {
  margin-bottom: 0;
}

.first-row img {
  margin: 20px;
  width: 90px;
}

.second-row img {
  margin: 20px;
  width: 90px;
}

.emphasize-text {
  display: none;

  @include mq('laptop') {
    display: block;
    width: 270px;
    padding: 20px;
    background: white;
    box-shadow: #00000030 2px 2px 2px;
    position: absolute;
    bottom: 180px;
    right: -10px;
  }

  @include mq('desktop') {
    display: block;
    width: 270px;
    padding: 20px;
    background: white;
    box-shadow: #00000030 2px 2px 2px;
    position: absolute;
    bottom: 180px;
    right: -10px;
  }

  @include mq('desktop-wide') {
    display: block;
    width: 270px;
    padding: 20px;
    background: white;
    box-shadow: #00000030 2px 2px 2px;
    position: absolute;
    bottom: 180px;
    right: -10px;
  }
}

.rich-text {
  a {
    color: $petra;
  }
}

.logo-color {
  color: $logo-color;
}

.first-check,
.second-check {
  img {
    width: 20px;
    height: auto;
  }
}

.comparison-table {
  .table {
    thead {
      th {
        font-weight: 500;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
    tbody {
      td, td p {
        font-size: 14px;
        vertical-align: middle;
      }

      td > p {
        margin: 0;
      }
    }
  }
}

.half-full {
  position: relative;
  width: 100%;
  min-height: 350px;
  background: $copper-light;

  @media only screen and (min-width: 768px) {
    min-height: 500px;
  }

  .img-right {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: calc(50vw - 15px);
    background-repeat: no-repeat;
    background-size: cover;

    @media only screen and (max-width: 768px) {
      position: absolute;
      width: 100%;
    }
  }
}

.pricing-gussy-logo {
  width: 100%;

  @include mq('tablet') {
    width: 40%;
  }
}
