@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';
.bg-light {
  background-color: #ffffff !important;
  box-shadow: 3px 3px 3px #00000020;
}

.nav-brand {
  @include mq('laptop') {
    margin-right: 50px;
  }
}

.nav-link-left {
  @include mq('tablet') {
    margin-right: 30px;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  @include mq('laptop') {
    margin-right: 30px;
    padding-right: 0;
    padding-left: 0;
  }

  @include mq('desktop') {
    margin-right: 30px;
    padding-right: auto;
    padding-left: auto;
  }

  @include mq('desktop-wide') {
    margin-right: 30px;
    padding-right: auto;
    padding-left: auto;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: $copper;
  color: white;
}

.dropdown-toggle::after {
  margin-left: 0.7em;
}

.lp-dropdown {
  .dropdown-menu {
    border-color: rgba($obsidian, 0.08);

    @include mq('tablet-lg') {
      box-shadow: 0px 6px 12px rgba($obsidian, 0.1);
    }
  }

  .dropdown-item {
    padding: 0.25rem 1rem;

    &:hover {
      background-color: rgba($petra, 0.05);
    }

    &.active,
    &:active {
      color: $obsidian;
    }
  }
}

.navbar {
  font-family: $font-montserrat;
  @include mq('phone-small') {
    padding: 10px;
  }

  @include mq('phone') {
    padding: 10px;
  }

  @include mq('phone-wide') {
    padding: 10px;
  }

  @include mq('phablet') {
    padding: 10px;
  }

  @include mq('tablet-small') {
    padding: 10px;
  }

  @include mq('tablet') {
    padding: 10px;
  }

  @include mq('laptop') {
    height: 68px;
    padding: 0px 70px;
  }

  @include mq('desktop') {
    height: 68px;
    padding: 0px 70px;
  }

  @include mq('desktop-wide') {
    height: 68px;
    padding: 0px 70px;
  }
}

.nav-divider {
  display: none;
  @include mq('laptop') {
    display: block;
  }

  @include mq('desktop') {
    display: block;
  }

  @include mq('desktop-wide') {
    display: block;
  }
}

.navbar-toggler:active,
.navbar-toggler:focus {
  outline: 0;
}

.nav-link,
.dropdown-item {
  color: $obsidian !important;
  font-size: 14px;
}
