#footer {
  background-color: white;
  color: $obsidian;
  padding: 30px 50px 10px 50px;
  letter-spacing: 0.05rem;
}

.footer-icon {
  margin-right: 15px;
}

.happiness-guarantee-img-cont {
  margin-bottom: 30px;
}

.happiness-guarantee-img {
  @include mq('phone-small') {
    width: 150px;
  }
  @include mq('phone') {
    width: 150px;
  }
  @include mq('phone-wide') {
    width: 150px;
  }
  @include mq('phablet') {
    width: 150px;
  }
  @include mq('tablet-small') {
    width: 100px;
  }
  @include mq('tablet') {
    width: 100px;
  }

  @include mq('laptop') {
    width: 100px;
  }

  @include mq('desktop') {
    width: 100%;
  }

  @include mq('desktop-wide') {
    width: 100%;
  }
}

#contact-info {
  margin: 30px 0px;
}

.line-divide {
  margin: 0 6px;
  display: none;
  @include mq('laptop') {
    display: inline-block;
  }

  @include mq('desktop') {
    display: inline-block;
  }

  @include mq('desktop-wide') {
    display: inline-block;
  }
}

#footer a:hover {
  text-decoration: none;
  color: $petra;
  transition: ease 0.2s;
}

.footer-link {
  color: $obsidian;
  display: block;
  margin-bottom: 12px;
  text-decoration: none;
}

.footer-logo {
  margin-bottom: 20px;
}

.footer-col-div {
  padding: 20px;

  .body-1-reg {
    color: $obsidian;
  }
}

svg {
  &.footer-icon {
    path {
      fill: $obsidian;
    }
  }
}

.contact-link {
  color: white;
  text-decoration: none;
}

#footer .contact-link {
  font-size: 14px;
  margin-bottom: 12px;
}

#copyright {
  color: $smoke;
  font-size: 12px;
  margin-bottom: 0;
}
.privacy-terms-link {
  color: $obsidian;
  font-size: 14px;
  margin-bottom: 12px;
  text-decoration: none;
}
