@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.shop-container {
  @media (min-width: 1200px) {
    max-width: 1300px;
  }
}

.shop-header {
  .shop-header-text {
    padding: 0 15px;
  }

  @media (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
    .shop-header-text {
      padding: 0;
    }
  }
}

.search-bar-div {
  padding-left: 0;
  
  @include mq("desktop") {
    padding-left: 3.7rem;
  }
}

.shop-breadcrumb {
  .breadcrumb {
    background: none;
    a {
      color: $petra;
      &:hover {
        text-decoration: none;
        color: $red-desert;
      }
    }
  }
}

.shop-search-bar {
  .form-control {
    border-right: transparent;
  }

  .input-group-append .input-group-text {
    background-color: transparent;
  }
}

.shop-breadcrumb {
  display: none;

  @include mq("laptop") {
    display: block;
  }
}

.shop-filter-container {
  width: 100%;

  @include mq("tablet") {
    width: 250px;
  }
}

.filter-modal {
  padding: 0 !important;

  .modal-dialog {
    max-width: 100%;
    margin: 0;
  }

  .modal-content {
    border-radius: 0;
    min-height: 100vh;
  }

  .modal-body {
    border-radius: 0;
  }

  .modal-back {
    padding: 6px 0;
    color: $smoke;
    font-size: 14px;

    &:hover {
      text-decoration: none;
    }
  }
}

.pagination {
  .page-link {
    border: transparent;
    color: $obsidian;
    border-radius: 0;
  }

  .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .page-item:last-child .page-link {
    margin-left: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .page-item.active .page-link {
    background-color: $petra;
    border-color: transparent;
  }
}

/* INITIALIZERS + DEFAULTS
 * ============================== */
.Product > img,
.Line-item__img > img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

/* BASE APP
  * ============================== */

.App__customer-actions {
  float: left;
  padding: 10px;
}

.App__title {
  padding: 80px 20px;
  text-align: center;
}

.Product-wrapper {
  max-width: 900px;
  margin: 40px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.App__view-cart-wrapper {
  float: right;
}

.App__view-cart {
  font-size: 15px;
  border: none;
  background: none;
  cursor: pointer;
  color: white;
}

.cartBtn {
  background-color: $copper;
  position: fixed;
  border: 0;
  right: -5px;
  top: 50%;
  z-index: 2;
}

.cart-img {
  width: 30px;
}

.button {
  background-color: $copper;
  color: white;
  border: none;
  font-size: 12px;
  padding: 5px 35px;
  cursor: pointer;
  border-radius: 30px;
}

.button:hover,
.button:focus,
.cartBtn:hover,
.cartBtn:focus {
  background-color: $petra;
}

.button:disabled {
  background: #bfbfbf;
  cursor: not-allowed;
}

.add-to-cart-square {
  background-color: $copper;
  color: white;
  border: none;
  font-size: 12px;
  padding: 5px 35px;
  cursor: pointer;
  border-radius: 0px;
}

.category-btn {
  border: 0;
  color: $smoke;
  background: none !important;
}

.next-btn {
  border: 0;
  color: $copper;
  background: none !important;
}

.search-btn {
  cursor: pointer;
}

.search-input {
  font-size: 12px;
}

.shop-sidebar {
  padding-top: 130px;
  @include mq("laptop", "max") {
    padding-top: 50px;
  }
}

.black-overlay {
  @include mq("laptop", "max") {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #00000099;
    z-index: 9998;
  }
}

.refine-search-div {
  @include mq("laptop", "max") {
    position: fixed;
    left: -320px;
    bottom: 0;
    background-color: white;
    height: 100%;
    width: 320px;
  }
  transition: 0.15s ease-out;
}

.filterDisplay {
  @include mq("laptop", "min") {
    display: none;
  }
}

.filter-img {
  width: 20px;
  margin-right: 5px;
  margin-top: -3px;
}

.refine-search-div-open {
  @include mq("laptop", "max") {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9999;
    background-color: white;
    height: 100%;
    width: 320px;
    transition: 0.15s ease-in-out;
    overflow-y: scroll;
  }
}

.product-categories {
  margin-top: -15px;
  padding-inline-start: 20px;
  line-height: 1.7;
  list-style-type: none;
}

.product-categories a {
  text-decoration: none;
  color: $smoke;
}

.product-categories a:hover {
  color: $copper;
}

.login {
  font-size: 1.2rem;
  color: #b8b8b8;
  cursor: pointer;
}

.login:hover {
  color: white;
}

.Flash__message-wrapper {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  pointer-events: none;
  z-index: 227;
  left: 50%;
  transform: translateX(-50%);
}

.Flash__message {
  background: rgba(0, 0, 0, 0.88);
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  cursor: default;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: none;
  pointer-events: auto;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  padding: 10px 20px;
  margin: 0;
}

.Flash__message--open {
  display: flex;
}

/* CART
  * ============================== */
.Cart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 350px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e5e5e5;
  transform: translateX(100%);
  transition: transform 0.15s ease-in-out;
  z-index: 9999;
}

.Cart--open {
  transform: translateX(0);
  overflow-y: scroll;
}

.Cart__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
}

.Cart__header {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  flex: 0 0 auto;
  display: inline-block;
}

.Cart__line-items {
  flex: 1 0 auto;
  margin: 0;
  padding: 20px;
}

.Cart__footer {
  padding: 20px;
  border-top: 1px solid #e5e5e5;
  flex: 0 0 auto;
}

.Cart__checkout {
  margin-top: 20px;
  display: block;
  width: 100%;
}

.Cart-info {
  padding: 15px 20px 10px;
}

.Cart-info__total {
  float: left;
  text-transform: uppercase;
}

.Cart-info__small {
  font-size: 11px;
}

.Cart-info__pricing {
  float: right;
}

.pricing {
  margin-left: 5px;
  font-size: 16px;
  color: black;
}

/* LINE ITEMS
  * ============================== */
.Line-item {
  margin-bottom: 20px;
  overflow: hidden;
  backface-visibility: visible;
  min-height: 65px;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.Line-item__img {
  width: 65px;
  height: 65px;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ffffff;
  position: absolute;
}

.Line-item__content {
  width: 100%;
  padding-left: 75px;
}

.Line-item__content-row {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
  text-align: left;
}

.Line-item__variant-title {
  float: right;
  font-weight: bold;
  font-size: 11px;
  line-height: 17px;
  color: #767676;
}

.Line-item__title {
  color: #4e5665;
  font-size: 15px;
  font-weight: 400;
}

.Line-item__price {
  line-height: 23px;
  float: right;
  font-weight: bold;
  font-size: 15px;
  margin-right: 40px;
}

.Line-item__quantity-container {
  border: 1px solid #767676;
  float: left;
  border-radius: 3px;
}

.Line-item__quantity-update {
  color: #767676;
  display: block;
  float: left;
  height: 21px;
  line-height: 16px;
  font-family: monospace;
  width: 25px;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}

.Line-item__quantity-update-form {
  display: inline;
}

.Line-item__quantity {
  color: black;
  width: 38px;
  height: 21px;
  line-height: 23px;
  font-size: 15px;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  border-left: 1px solid #767676;
  border-right: 1px solid #767676;
  display: block;
  float: left;
  padding: 0;
  border-radius: 0;
}

.Line-item__remove {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: 0;
  font-size: 20px;
  top: -4px;
  opacity: 0.5;
}

.Line-item__remove:hover {
  opacity: 1;
  cursor: pointer;
}

/* PRODUCTS
  * ============================== */
.Product {
  flex: 0 1 31%;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 3%;
  border: solid 1px $gray6;
  padding: 20px;
}

.Product:hover {
  border: solid 1px $copper;
}

.Product__img {
  width: 150px;
  height: 150px;
  border-radius: 3px;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
}

.Product:hover {
  .Product__img {
    opacity: 0.5;
  }
  cursor: pointer;
}

.Product__title {
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  opacity: 0.7;
}

.Product__price {
  display: block;
  font-size: 1.1rem;
  opacity: 0.5;
  margin-bottom: 0.4rem;
}

.Product__option {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.Product__quantity {
  display: block;
  width: 15%;
  margin-bottom: 10px;
}

/* CUSTOMER AUTH
  * ============================== */
.CustomerAuth {
  background: #2a2c2e;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  padding: 0 0 65px;
  top: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  transition: opacity 150ms;
  opacity: 1;
  visibility: visible;
  z-index: 1000;
  position: fixed;
}

.CustomerAuth--open {
  display: block;
}

.CustomerAuth__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
}

.CustomerAuth__body {
  padding: 130px 30px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  position: relative;
}

.CustomerAuth__heading {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 15px;
}

.CustomerAuth__credential {
  display: block;
  position: relative;
  margin-bottom: 15px;
  border-radius: 3px;
}

.CustomerAuth__input {
  height: 60px;
  padding: 24px 10px 20px;
  border: 0px;
  font-size: 18px;
  background: #fff;
  width: 100%;
}

.CustomerAuth__submit {
  float: right;
}

.error {
  display: block;
  font-size: 15px;
  padding: 10px;
  position: relative;
  min-height: 2.64286em;
  background: #fbefee;
  color: #c23628;
}

.modal-header {
  border: 0;
}

.width-full {
  width: 100%;
}
