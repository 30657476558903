.vendors img {
  height: 80px;
  object-fit: contain;
  width: 90px;

  @include mq('phablet') {
    width: auto !important;
    max-height: 100px;
  }
}

.payment-gateways img {
  height: 60px;
  object-fit: contain;
}

.react-multi-carousel-list {
  button:focus {
    outline: none;
  }
}

.react-multi-carousel-list button:focus {
  background: rgba(0, 0, 0, 0.5);

  button:focus {
    outline: none;
  }
}
