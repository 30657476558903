@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.product-thumbnail {
  text-align: center;
  padding: 10px;
  margin-bottom: 40px;
  min-height: 310px;
  background-color: white;
  margin: 10px;
  border-radius: 10px;

  @include mq('phablet') {
    margin: 5px;
  }

  .product-image-div {
    display: flex;
    justify-content: center;
  }

  .product-image {
    width: 80%;
    height: 160px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-bottom: 16px;

    @include mq('phablet', max) {
      min-width: 110px;
      position: relative;
      left: 42%;
      transform: translateX(-50%);
    }
  }

  .product-title {
    font-family: $font-cormorant;
    font-size: 16px;
    color: $obsidian;
  }

  .product-price {
    font-size: 12px;
    line-height: 170%;
    color: rgba($obsidian, 0.8);
    margin-bottom: 12px;
  }

  .original-price {
    color: #d0765b;
    margin-right: 8px;
    text-decoration: line-through;
  }

  .discount {
    color: #d0765b;
  }

  .product-link {
    &:hover {
      text-decoration: none;
    }
  }

  &:hover {
    border-radius: 6px;
    -webkit-box-shadow: inset 0px 0px 0px 1px $petra;
    -moz-box-shadow: inset 0px 0px 0px 1px $petra;
    box-shadow: inset 0px 0px 0px 1px $petra;
  }
}
