.card-faqs {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.125);

  .card-header {
    padding-left: 0;

    &:hover {
      cursor: pointer;
    }
  }
}

.search-icon {
  background-color: transparent;
  border-right: none;
  color: $lintel;
  border-radius: 0;
}

.search-input {
  border-left: none;
  color: $smoke;
  border-radius: 0;
}

.search-input:focus {
  box-shadow: none;
  border: 1px solid #ced4da;
  border-left: none;
  border-radius: 0;
}

.card-faqs {
  border-radius: 0;
}

.card-faqs .card-header {
  font-size: 14px;
  font-weight: 600;
  background: none;
  border-bottom: none;
}

.card-faqs .card-body {
  font-size: 14px;
  font-weight: 400;
  padding: 0;

  a {
    color: $petra;
  }
}

.faq-arrow {
  right: 0;
  position: absolute;
}
