.banner-icon {
  height: 60px;
  margin: 30px 0px;
}

.banner-img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
