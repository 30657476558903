////////// pseudo
@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}
//usage:
// div::after {
//     @include pseudo;
//     top: -1rem; left: -1rem;
//     width: 1rem; height: 1rem;
// }

///////// media query
$breakpoints: (
    "phone-small":  320px,
    "phone":        375px,
    "phone-wide":   425px,
    "phablet":      560px,
    "tablet-small": 640px,
    "tablet":       768px,
    "tablet-lg":    992px,
    "laptop":       1024px,
    "desktop":      1248px,
    "desktop-wide": 1440px,
    "desktop-wider": 1920px,
);
@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}
//usage:
// .site-header {
//     padding: 2rem;
//     font-size: 1.8rem;
//     @include mq('laptop') {
//         padding-top: 4rem;
//         font-size: 2.4rem;
//     }
// }

//////// Z-INDEX 

@function z($name) {
    @if index($z-indexes, $name) {
        @return (length($z-indexes) - index($z-indexes, $name)) + 1;
    } @else {
        @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
        @return null;
    }
}
$z-indexes: (
    "outdated-browser",
    "modal",
    "site-header",
    "page-wrapper",
    "site-footer"
);

//usage:
// .site-header {
//     z-index: z('site-header');
// }

///// font

@mixin font($font-family, $font-file) {
    @font-face {
      font-family: $font-family;
      src: url($font-file+'.eot');
      src: url($font-file+'.eot?#iefix') format('embedded-opentype'),
           url($font-file+'.woff') format('font-woff'),
           url($font-file+'.ttf') format('truetype'),
           url($font-file+'.svg#aller') format('svg');
      font-weight: normal;
      font-style: normal;
    }
  }