@import "App";

@import "assets/styles/variables";
@import "assets/styles/mixins";

@import "components/FaqsComponent/faqscomponent";
@import "components/FooterComponent/footercomponent";
@import "components/LandingPage/BannerLPComponent/bannerlpcomponent";
@import "components/PricingPackageComponent/pricingpackagecomponent";
@import "components/ShopComponents/CheckoutLoginModal/checkoutLoginModal";
@import "components/ShopComponents/ShopNav/shopnav";
@import "components/ShopComponents/ProductCarousel/productcarousel";
@import "components/ShopComponents/SearchBar/searchbar";
@import "pages/ShopNewPage/shopnewpage";
@import "pages/ShopPage/shoppage";
@import "pages/ShopGetThatLookPage/shopgetthatlookpage";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
