.shop-nav {
  background-color: $gray9;
  height: 40px;
  display: flex;
  margin-top: 70px;
  align-items: center;
  border-top: solid 1px $gray6;
  border-bottom: solid 1px $gray6;
  position: relative !important;
  overflow-x: scroll;
  display: flex;
  padding-left: 50px;
  padding-right: 25px;
  &::before, &::after {
    content: '';
    flex: 1;
  }

  .nav-link {
    @include mq("desktop") {
      padding: .5rem 1.25rem;
    }

    @include mq("desktop-wide") {
      padding: .5rem 1.5rem;
    }

    @include mq("desktop-wider") {
      padding: .5rem 1.75rem;
    }
  }

  .carousel{
    height: 100%;
    width: -webkit-fill-available;
  }

  .carousel-inner{
    overflow: visible;
    overflow-x: clip;
  }

  .carousel-indicators li { 
    display:none;
  }

  .carousel-arrow {
    background: none;
    display: block;
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 998;
    border: 0;
    min-height: 40px;
    opacity: 1;
    cursor: pointer;
  }

  .carousel-arrow.right {
    right: 1vw;

    @include mq("desktop-wide") {
      right: 4vw;
    }
  
    @include mq("desktop-wider") {
      right: 6vw;
    }
  }

  .carousel-arrow.left {
    left: 1vw;

    @include mq("desktop-wide") {
      left: 4vw;
    }

    @include mq("desktop-wider") {
      left: 6vw;
    }
  }
  
  .shop-item{
    justify-content: center;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  .shop-nav-mob {
    display: block;

    .dropdown-toggle {
      margin: 5px;
      font-size: 12px;
    }
  }

  @include mq("laptop") {
    overflow-x: visible;
  }

  .dropdown-menu {
    display: block;
    position: absolute;
  }

  .dropdown-toggle {
    background: none;
    border: none;
    color: $obsidian;
    transition: ease 0.2s;
    font-size: 12px;

    @include mq("desktop-wide") {
      font-size: 14px;
    }
  
    @include mq("desktop-wider") {
      font-size: 16px;
    }
  
    &:hover {
      color: $petra;
    }

    &::after {
      display: none;;
    }

    &:focus, &:active {
      background: none !important;
      color: $obsidian !important;
      box-shadow:none !important;
      outline:0px !important;
    }
  }

  .dropdown-toggle:active {
    background-color: none;
  }


  .show {
    .dropdown-toggle {
      background: none;
      border: none;
      color: $obsidian;
      box-shadow: none;
      overflow: visible;
    }
  }
}

.shop-nav ul {
  list-style: none;
}

.shop-nav li {
  float: left;
}

.shop-nav li a {
  display: block;
  color: black;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}