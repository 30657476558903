@import '../../assets/styles/variables';

.contact-header {
  color: $copper;
  font-size: 13px;
  font-weight: 600;
  font-family: $font-montserrat;
}

.contact-us-link:hover {
  color: $copper;
  transition: ease-in 0.2s;
  text-decoration: none;
}

.form-group {
  margin-bottom: 24px;

  label {
    font-size: 14px;
  }
}

.form-control {
  &:focus {
    border-color: $petra;
    box-shadow: 0 0 0 0.2rem rgba($petra, 0.25);
  }
}

.schedule-info {
  display: block;
  background-color: $petra-light;
  border-radius: 4px;
  padding: 16px;
  color: $obsidian;
  margin-bottom: 24px;

  .schedule-details {
    color: $obsidian;

    p {
      font-size: 14px;
      margin: 0;
    }
  }

  &:hover {
    text-decoration: none;
  }
}
