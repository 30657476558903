.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(0, 11%, 93%);
    }
    100% {
      background-color: hsl(240, 5%, 79%);
    }
  }
}

.skeleton.navlist {
  width: 95%;
  height: 20px;
  border-radius: 10px;
}

.skeleton.home-banner {
  max-width: 100%;
  height: 80vh;
}

.skeleton.before-after-banner {
  border-radius: 20px;
  width: 100%;
  height: 500px;
}

.skeleton.shop-banner {
  border-radius: 20px;
  min-width: none;
  max-width: 100%;
  height: 460px;
}

.skeleton.back-to-shop {
  border-radius: 10px;
  width: 120px;
  height: 12px;
  display: inline-block;
}

.skeleton.video-link {
  margin: auto;
  border-radius: 20px;
  width: 100%;
  height: 300px;
  
}

.skeleton.package-1-images {
  margin: auto;
  border-radius: 20px;
  width: 290px;
  height: 300px;
  margin-bottom: 20px;
}

.skeleton.get-that-look-img {
  margin: auto;
  border-radius: 20px;
  width: 450px;
  height: 300px;
  margin-bottom: 20px;
}

.skeleton.product-img {
  margin: auto;
  border-radius: 20px;
  width: 280px;
  height: 280px;
  margin-bottom: 20px;
}
.skeleton.related-product-img {
  margin: auto;
  border-radius: 20px;
  width: 80%;
  height: 200px;
  margin-bottom: 20px;
}
.skeleton.steps-img {
  margin: auto;
  border-radius: 20px;
  width: 100%;
  height: 186px;
  margin-bottom: 20px;
}

.skeleton.big-img {
  border-radius: 20px;
  max-width: 100%;
  height: 80vh;
}

.skeleton.h2-size {
  border-radius: 10px;
  width: 250px;
  height: 45px;
  margin-bottom: 10px;
  display: inline-block;
}
.skeleton.h2-size-long {
  border-radius: 10px;
  width: 70%;
  height: 45px;
  margin-bottom: 10px;
  display: inline-block;
}
.skeleton.h3-size {
  border-radius: 10px;
  display: inline-block;
  width: 270px;
  height: 40px;
  margin-bottom: 10px;
}

.skeleton.h4-size {
  border-radius: 10px;
  display: inline-block;
  width: 120px;
  height: 30px;
  margin-bottom: 10px;
}

.skeleton.text-paragraph {
  display: inline-block;
  margin-bottom: 10px;
  border-radius: 20px;
  width: 75%;
  height: 10px;
}
.skeleton.text-paragraph-short {
  display: inline-block;
  margin-bottom: 10px;
  border-radius: 20px;
  width: 30ch;
  height: 10px;
}

.skeleton.featured-before-schematic {
  border-radius: 20px;
  width: 100%;
  height: 320px;
}

.skeleton.featured-after {
  width: 100%;
  border-radius: 20px;
  height: 570px;
}

.skeleton.etc {
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
  height: 20px;
  border-radius: 20px;
}
