@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.carousel {
  @include mq('phone-small') {
    height: 400px;
  }

  @include mq('phone') {
    height: 400px;
  }

  @include mq('phone-wide') {
    height: 400px;
  }

  @include mq('phablet') {
    height: 400px;
  }

  @include mq('tablet-small') {
    height: 400px;
  }

  @include mq('tablet') {
    height: 400px;
  }

  @include mq('laptop') {
    height: auto;
  }

  @include mq('desktop') {
    height: auto;

    .btntext-18-sb {
      position: absolute;
      bottom: 20px;
      width: 80%;
      left: 55px;
    }
  }

  @include mq('desktop-wide') {
    height: auto;
  }
}

.carousel-item img {
  @include mq('phone-small') {
    object-fit: cover;
    height: 400px;
  }

  @include mq('phone') {
    object-fit: cover;
    height: 400px;
  }

  @include mq('phone-wide') {
    object-fit: cover;
    height: 400px;
  }

  @include mq('phablet') {
    object-fit: cover;
    height: 400px;
  }

  @include mq('tablet-small') {
    object-fit: cover;
    height: 400px;
  }

  @include mq('tablet') {
    object-fit: cover;
    height: 400px;
  }

  @include mq('laptop') {
    height: auto;
  }

  @include mq('desktop') {
    height: auto;
  }

  @include mq('desktop-wide') {
    height: auto;
  }
}

.carousel-indicators li {
  width: 8px;
  border-radius: 50%;
  height: 8px;
}

.carousel-label {
  @include mq('phone-small') {
    margin-bottom: 50px;
  }

  @include mq('phone') {
    margin-bottom: 50px;
  }

  @include mq('phone-wide') {
    margin-bottom: 50px;
  }

  @include mq('phablet') {
    margin-bottom: 50px;
  }

  @include mq('tablet-small') {
    margin-bottom: 50px;
  }

  @include mq('tablet') {
    margin-bottom: 50px;
  }

  @include mq('laptop') {
    margin-bottom: 60px;
  }

  @include mq('desktop') {
    margin-bottom: 140px;
  }

  @include mq('desktop-wide') {
    margin-bottom: 140px;
  }
}
