@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';
.nav-stylequiz {
  background-color: white;
  @include mq('desktop') {
    height: 68px;
  }

  @include mq('desktop-wide') {
    height: 68px;
  }
}

.loading {
  height: 5px;
  background-color: $gray6;
}

.loading-bar {
  background-color: $copper;
  height: 5px;
}

.nav-stylequiz {
  position: relative;
}
.brand {
  position: absolute;
  left: 50%;
  margin-left: -50px !important; /* 50% of your logo width */
  display: block;
}
