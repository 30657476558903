.prodtype-row {
  padding: 15px;

  .prodtype-col {
    padding: 5px;

    a {
      color: initial;
    }
  }

  .card {
    min-height: 180px;

    .prodtype-img-div {
      min-height: 50px;
      display: flex;
      justify-content: center;
    }
  
    .prodtype-card-text-div {
      padding: 1rem;
      p {
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
  }
}

.prodtype-row .prodtype-col:first-child {
  .prodtype-img-div {
    padding-top: 40px;
    padding-bottom: 30px;
    img {
      width: 30px;
    }
  }
}

.prodtype-row .prodtype-col {
  img {
    width: 120px;
    max-height: 100px;
  }
}