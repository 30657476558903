@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.react-multi-carousel-list {
  button:focus {
    outline: none;
  }
}

.react-multi-carousel-list button:focus {
  background: rgba(0, 0, 0, 0.5);

  button:focus {
    outline: none;
  }
}

.feedback-container {
  background-color: #fcf1ed;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 15px;
}

.banner-description {
  line-height: 10px;
}

.banner-title-container {
  vertical-align: bottom;
}

.banner-title {
  display: inline-block;
}

.gussy-logo {
  vertical-align: baseline;

  @include mq('phone') {
    height: 20px;
  }
  @include mq('laptop') {
    height: 30px;
  }
  @include mq('desktop') {
    height: 30px;
  }
  @include mq('desktop-wide') {
    height: 30px;
  }
}

.gussy-feedback {
  justify-content: center;
  align-items: center;
}

.feedback-star {
  display: flex;
  justify-content: center;
  align-items: center;
}
.interior-img {
  height: 400px;
  width: 80%;
  overflow: hidden;
  margin: auto;

  img {
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
  }
}

.view-design-button {
  background-color: $petra;
  color: white;
}
