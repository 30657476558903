@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

// Custom Carousel
.carousel-lp {
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  background-position: center 5%;

  @media only screen and (min-width: 1540px) {
    background-position: center 35%;
  }
}

.carousel {
  .carousel-indicators {
    bottom: -40%;

    @media only screen and (min-width: 1021px) {
      bottom: 20%;
    }
  }

  .carousel-item {
    height: 100vh;

    .carousel-caption {
      @include mq('phone-small') {
        top: 20%;
        left: 10%;
        right: 10%;
      }

      @include mq('phablet') {
        top: 35%;
      }

      @include mq('tablet') {
        top: 40%;
      }
    }

    .carousel-caption-1 {
      @include mq('tablet') {
        top: 50%;
      }
    }
  }

  h1 {
    @include mq('phone-small') {
      font-size: 50px;
    }

    @include mq('tablet') {
      font-size: 60px;
    }
  }
}

.landing-logo {
  padding-right: 5px;
  margin-top: -5px;

  @include mq('phone-small') {
    height: 24px;
  }

  @include mq('phone') {
    height: 24px;
  }

  @include mq('phone-wide') {
    height: 24px;
  }

  @include mq('phablet') {
    height: 24px;
  }

  @include mq('tablet-small') {
    height: 24px;
  }

  @include mq('tablet') {
    height: 24px;
  }

  @include mq('laptop') {
    height: 24px;
  }

  @include mq('desktop') {
    height: 32px;
  }
}

.cookies-link {
  color: #d1745b;
  text-decoration: underline;
}

.brand-orange {
  color: $logo-color !important;
}

.h176 {
  font-weight: 600;

  @include mq('tablet-lg') {
    font-size: 60px !important;
    font-weight: 600;
  }

  @include mq('laptop') {
    font-size: 76px !important;
    font-weight: 600;
  }
}

.w-auto {
  width: auto;
}

.left-offset {
  @media only screen and (min-width: 1195px) {
    position: relative;
    left: -40%;
    width: 130% !important;
  }
}

.cart-lp {
  width: 10%;
  position: relative;
  animation-name: cartdash;
  animation: cartdash 10s 10;
  animation-delay: 3s;

  @include mq('tablet') {
    width: 6%;
  }
}

@keyframes cartdash {
  from {
    left: -300px;
  }
  5% {
    left: -300px;
  }
  10% {
    left: -300px;
  }
  15% {
    left: -290px;
    transform: rotate(-40deg);
  }
  20% {
    left: -250px;
    transform: rotate(-10deg);
  }
  90% {
    left: 0px;
    transform: rotate(0);
  }
  to {
    left: 0px;
  }
}

.phone-lp {
  position: relative;
  animation: phonering 2s infinite;
}

@keyframes phonering {
  0% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(0);
  }
  20% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(-3deg);
  }
  35% {
    transform: rotate(3deg);
  }
  40% {
    transform: rotate(-3deg);
  }
  60% {
    transform: rotate(5deg);
  }
  65% {
    transform: rotate(0);
  }
  70% {
    transform: rotate(0);
  }
  75% {
    transform: rotate(0);
  }
  80% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

.ba-compare {
  div {
    div {
      div {
        background: $petra !important;
      }
    }
  }
}

.float-container {
  position: relative;
}

.float-text {
  position: absolute;
  bottom: 0%;
  width: 40%;
  left: 8%;
  text-shadow: 0px 2.12102px 21.2102px rgba(0, 0, 0, 0.24);

  .f60 {
    font-size: 24px;

    @include mq('tablet') {
      font-size: 50px;
    }

    @include mq('laptop') {
      font-size: 60px;
    }
  }
}

.float-home {
  background-image: url('../../assets/img/landing/house.svg');
  background-position: 40% 10%;
  background-size: 15%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  @include mq('tablet') {
    background-position: 26% 20%;
    background-size: 30%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
  @include mq('laptop') {
    background-position: 30% 20%;
    background-size: 20%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  @include mq('desktop') {
    background-position: 35% 10%;
  }
}

.float-money {
  background-image: url('../../assets/img/landing/money.svg');
  background-position: 40% 10%;
  background-size: 15%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  @include mq('tablet') {
    background-position: 26% 20%;
    background-size: 30%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  @include mq('laptop') {
    background-position: 30% 20%;
    background-size: 20%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  @include mq('desktop') {
    background-position: 35% 10%;
  }
}

.float-heart {
  background-image: url('../../assets/img/landing/heart.svg');
  background-position: 40% 10%;
  background-size: 15%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  @include mq('tablet') {
    background-position: 26% 20%;
    background-size: 30%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  @include mq('laptop') {
    background-position: 30% 20%;
    background-size: 20%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  @include mq('desktop') {
    background-position: 35% 10%;
  }
}
