@import '../../../assets/styles/variables';

.beforeafter-div {
  background-color: white;
}

.beforeafter-div .btn-light {
  background-color: $copper;
  color: white;
  border-radius: 50px;
  font-size: 20px;
  box-shadow: 3px 3px 3px #00000030;
  width: 250px;
  letter-spacing: 3px;
  border: 0px;
  margin-top: 10px;
}

.beforeafter-div .btn-light:hover {
  background-color: white;
  color: $obsidian;
  border: 0px;
}
