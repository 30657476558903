@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.f-bold {
  font-weight: bold;
}

.f-semibold {
  font-weight: 600;
}

.f-medium {
  font-weight: 500;
}

.f-13 {
  font-size: 13px;
}

.pricing-list li {
  margin-bottom: 20px;
}

.pricing-hr {
  margin: 50px 0px;
}

.pricing-img-div {
  height: 200px;
}

.pricing-img {
  width: 100%;
  object-fit: contain;
}

.pricing-page-card {
  padding: 20px;
}

.pricing-page-card:hover {
  border-radius: 6px;
  box-shadow: 0px 0px 0px 1px $petra inset;
}

.peso-sign {
  font-size: 1.125rem;
  font-family: $font-montserrat;
}

.pricing-odd,
.pricing-even {
  img {
    height: auto;
  }

  .badge-secondary {
    color: #fff;
    background-color: #6c757d;
    border-radius: 0;
    padding: 5px 10px;
    -ms-transform: skewy(-3deg); /* IE 9 */
    transform: skewy(-3deg); /* Standard syntax */
  }
}

.pricing-even {
  display: flex;

  @media screen and (max-width: 769px) {
    .sm-order-1 {
      order: 1;
    }
    .sm-order-2 {
      order: 2;
    }
    .sm-order-3 {
      order: 3;
    }
  }
}

.obj-cover {
  object-fit: cover;
  height: 100%;
}

.old-price {
  font-size: 1rem;
  font-weight: normal;
  margin-right: 20px;
  font-family: $font-montserrat;
}

.pricing-page-cards {
  .copper-label {
    font-family: $font-montserrat;
    color: #ffffff;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: $copper;
    font-weight: normal;
    margin-bottom: 10px;
    border-radius: 0px;
  }
}

.h400 {
  @include mq('phone-small') {
    height: 300px !important;
  }

  @include mq('desktop') {
    height: 400px !important;
  }
}

.h290 {
  height: 290px !important;
}

.h100 {
  height: 100px !important;
}

.h100p {
  height: 100% !important;
}

.col-lg-half-offset {
  @include mq('laptop') {
    margin-left: 4.166666667%;
  }
}

.pricing-packages {
  img {
    @include mq('phone') {
      width: 50%;
    }

    @include mq('tablet') {
      width: 100%;
    }
  }

  @include mq('tablet') {
    padding: 3rem;
  }
}

.md-text-right {
  @include mq('tablet') {
    text-align: right !important;
  }
}

.accordion-arrow::before {
  content: url('../../assets/img/pricing/arrowUp.png');
  float: right !important;
  padding-left: 10px;
  position: relative;
  top: -3px;
}

.packages-section {
  display: flex;

  @media screen and (max-width: 769px) {
    .sm-order-1 {
      order: 1;
    }
    .sm-order-2 {
      order: 2;
    }
  }
}

.packages-cards {
  padding: 3rem;

  @include mq('phablet') {
    padding: 0;
  }
}

.full-bg-lightpetra {
  background-color: $petra-light;

  @include mq('phablet') {
    background: none;
    height: 380px;
    margin-top: 20px;
  }

  &:before {
    content: '';
    width: 100%;
    position: absolute;
    height: 280px;
    margin-top: 60px;
    left: 0;

    @include mq('phone-small') {
      background: none;
    }

    @include mq('phablet') {
      background-color: $petra-light;
      height: 380px;
      margin-top: 20px;
    }

    @include mq('tablet-small') {
      height: 380px;
      margin-top: 20px;
    }

    @include mq('tablet') {
      height: 300px;
      margin-top: 0px;
    }
  }
}

.right-bg-lightpetra {
  background-color: $petra-light;

  @include mq('phablet') {
    background: none;
    height: 380px;
    margin-top: 20px;
  }

  &:before {
    content: '';
    background-color: $petra-light;
    width: 100%;
    position: absolute;
    height: 300px;
    margin-top: 50px;
    right: 0;

    @include mq('phone-small') {
      background: none;
    }

    @include mq('phablet') {
      background-color: $petra-light;
      height: 300px;
      margin-top: 50px;
    }

    @include mq('tablet') {
      height: 250px;
      margin-top: -10px;
      width: 60%;
    }
  }
}

.left-bg-lightpetra {
  background-color: $petra-light;

  @include mq('phablet') {
    background: none;
    height: 380px;
    margin-top: 20px;
  }

  &:before {
    content: '';
    background-color: $petra-light;
    width: 100%;
    position: absolute;
    height: 350px;
    margin-top: 60px;
    left: 0;

    @include mq('phone-small') {
      background: none;
    }

    @include mq('phablet') {
      background-color: $petra-light;
      height: 300px;
      margin-top: 50px;
    }

    @include mq('tablet') {
      height: 300px;
      margin-top: 0px;
      width: 40%;
    }
  }
}

.italic-callout {
  font-style: italic;
  font-size: 14px;
  color: #43423d;
}

.more-details-btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: none;
  padding: 0;
}
