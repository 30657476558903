@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.choices-box {
  box-shadow: none !important;
  border: none !important;
  width: 190px;
  height: 120px;
  background-color: $gray;
  align-items: center;
  border: none;
  color: $obsidian;
  border-radius: 10px;
}

.choices-box:hover {
  background-color: $lintel;
  color: white;
}

.choices-box:active {
  border: none;
}

.choices-active {
  background-color: $copper;
  color: white;
}

.choices-active .choice-check {
  display: block !important;
}

.choice-check {
  position: absolute;
  top: 10px;
  right: 10px;
}

.btn-clear {
  background: none;
  border: none;
}
.btn-clear:hover {
  background: none;
  border: none;
}

.btn-question {
  background: none;
  border: none;
}
.btn-question:hover {
  background: none;
  border: none;
}
.btn-question:focus {
  outline: none;
  box-shadow: none;
}

.img-choices .choice-img {
  width: 280px;
  height: 200px;
  cursor: pointer;
  border-radius: 10px;
  object-fit: cover;
}

@include mq("desktop-wide") {
  .img-choices .choice-img  {
    width: 310px;
    height: 230px;
  }
}

.img-choices .choice-img-check {
  width: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.img-choices-active .choice-img {
  box-shadow: #00000040 3px 3px 3px;
}

.img-choices-active .choice-img-check {
  display: block !important;
}

.budget-input {
  border: none !important;
  border-bottom: solid 2px black !important;
  border-radius: 0 !important;
}

.budget-php {
  font-size: 24px;
  font-weight: 500;
}

.bold {
  font-weight: 800;
}

.stylequiz-end p {
  @include mq("tablet", "min") {
    width: 525px;
  }
}

.lbl-or {
  color: gray;
  margin: 25px 0px;
  text-align: center;
  font-size: 12px;
}

.btn-3d-party {
  text-transform: none;
  padding: 10px auto;
  width: fit-content;
  margin: 0px 5px 20px 5px;
}
