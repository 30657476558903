@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.pricing-div {
  background-color: $copper;
}

.pricing-header {
  margin-bottom: 20px;
  letter-spacing: 3px;
}

.pricing-img {
  height: 200px;
  margin-bottom: 30px;
}

.pricing-card {
  height: 550px;
  padding: 5px;
  margin: 5px;
  text-align: center;
  color: $obsidian;
}

.strikethrough {
  position: relative;
}

.strikethrough:before {
  position: absolute;
  content: '';
  color: $copper;
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  border-color: inherit;

  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.card-title {
  font-family: $font-title;
  font-size: 30px;
  border-bottom: 1px solid $obsidian;
  margin-bottom: 30px;
}

.card-subtitle {
  font-size: 24px;
}

.package-1-images {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 100px;

  .image-1,
  .image-2 {
    padding: 15px;
    width: 100%;
  }

  @include mq('phablet') {
    .image-1,
    .image-2 {
      width: auto;
      height: 300px;
    }
  }
}

.package-2-images {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 100px;

  .image-1 {
    padding: 15px 0 15px 0;
    width: 100%;
  }

  .image-2 {
    padding: 15px 0 15px 0;
    width: 100%;
  }

  @include mq('phablet') {
    .image-1 {
      width: 49%;
      padding: 15px;
    }

    .image-2 {
      width: 51%;
      padding: 15px;
    }
  }

  @include mq('laptop') {
    .image-1 {
      padding: 0 15px 0 0;
    }

    .image-2 {
      padding: 0 0 0 15px;
    }
  }
}

.package-3-images {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 100px;

  .image-1,
  .image-2 {
    padding: 15px 0 15px 0;
    width: 100%;
  }

  @include mq('phablet') {
    .image-1 {
      width: 48%;
      padding: 15px;
    }

    .image-2 {
      width: 52%;
      padding: 15px;
    }
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-15deg);
  }
  40% {
    transform: rotate(18deg);
  }
  80% {
    transform: rotate(-18deg);
  }
  85% {
    transform: rotate(3deg);
  }
  90% {
    transform: rotate(-3deg);
  }
  95% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
