@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.nextLevelSection {
  background-color: $copper-light;
}

.projects-price {
  color: $petra;
}

.proj-div img {
  width: 100%;
  object-fit: cover;
}

.proj-images {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
}

.proj-image1 {
  @include mq('phone-small') {
    width: 100%;
    margin-bottom: 10px;
  }

  @include mq('phone') {
    width: 100%;
    margin-bottom: 10px;
  }

  @include mq('phone-wide') {
    width: 100%;
    margin-bottom: 10px;
  }

  @include mq('phablet') {
    width: 100%;
    margin-bottom: 10px;
  }

  @include mq('tablet-small') {
    width: 100%;
    margin-bottom: 10px;
  }

  @include mq('tablet') {
    width: 100%;
    margin-bottom: 10px;
  }

  @include mq('laptop') {
    width: 500px;
    position: relative;
    top: 0;
    left: 0;
  }

  @include mq('desktop') {
    width: auto;
    position: relative;
    top: 0;
    left: 0;
  }

  @include mq('desktop-wide') {
    width: auto;
    position: relative;
    top: 0;
    left: 0;
  }
}

.proj-image2 {
  @include mq('phone-small') {
    width: 100%;
  }

  @include mq('phone') {
    width: 100%;
  }

  @include mq('phone-wide') {
    width: 100%;
  }

  @include mq('phablet') {
    width: 100%;
  }

  @include mq('tablet-small') {
    width: 100%;
  }

  @include mq('tablet') {
    width: 100%;
  }

  @include mq('laptop') {
    width: 500px;
    position: absolute;
    top: 60px;
    left: 435px;
    box-shadow: #00000010 3px 3px 10px 5px;
  }

  @include mq('desktop') {
    width: auto;
    position: absolute;
    top: 60px;
    left: 515px;
    box-shadow: #00000010 3px 3px 10px 5px;
  }

  @include mq('desktop-wide') {
    width: auto;
    position: absolute;
    top: 60px;
    left: 515px;
    box-shadow: #00000010 3px 3px 10px 5px;
  }
}

.proj-image3 {
  @include mq('phone-small') {
    width: 100%;
  }

  @include mq('phone') {
    width: 100%;
  }

  @include mq('phone-wide') {
    width: 100%;
  }

  @include mq('phablet') {
    width: 100%;
  }

  @include mq('tablet-small') {
    width: 100%;
  }

  @include mq('tablet') {
    width: 100%;
  }

  @include mq('laptop') {
    width: 500px;
    position: absolute;
    top: 60px;
    left: 435px;
    box-shadow: #00000010 3px 3px 10px 5px;
  }

  @include mq('desktop') {
    width: auto;
    position: absolute;
    top: 60px;
    left: 515px;
    box-shadow: #00000010 3px 3px 10px 5px;
  }

  @include mq('desktop-wide') {
    width: auto;
    position: absolute;
    top: 60px;
    left: 515px;
    box-shadow: #00000010 3px 3px 10px 5px;
  }
}

.proj-card {
  border: none;
}

.proj-card .card-header {
  background: none;
  cursor: pointer;
  border: none;
}

.vs-line {
  display: none;

  @include mq('laptop') {
    display: block;
  }

  @include mq('desktop') {
    display: block;
  }

  @include mq('desktop-wide') {
    display: block;
  }

  @include mq('laptop') {
    display: block;
  }
}

.vs-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #{$obsidian + '08'};
  padding: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.v-line {
  height: 100%;
  width: 0;
  border: 1px solid #{$obsidian + '08'};
}

.proj-arrow {
  width: 24px !important;
}

.no-indent-list {
  margin-left: 0px;
  padding: 0px 20px;
}

.inq-now-btn {
  padding: 13px 55px;
}

.inq-now-btn:active {
  background-color: $petra !important;
}

.proj-img-caption1 {
  background: white;
  position: absolute;
  top: 5px;
  left: 5px;
  padding: 5px;
}

.proj-img-caption2 {
  background: white;
  position: absolute;
  right: 5px;
  padding: 5px;

  @include mq('phone-small') {
    bottom: 5px;
  }

  @include mq('phone') {
    bottom: 5px;
  }

  @include mq('phone-wide') {
    bottom: 5px;
  }

  @include mq('phablet') {
    bottom: 5px;
  }

  @include mq('tablet-small') {
    bottom: 5px;
  }

  @include mq('tablet') {
    bottom: 5px;
  }

  @include mq('laptop') {
    top: 65px;
    bottom: auto;
  }

  @include mq('desktop') {
    top: 65px;
    bottom: auto;
  }

  @include mq('desktop-wide') {
    top: 65px;
    bottom: auto;
  }
}

.proj-img-caption3 {
  background: white;
  position: absolute;
  right: 5px;
  padding: 5px;

  @include mq('phone-small') {
    bottom: 5px;
  }

  @include mq('phone') {
    bottom: 5px;
  }

  @include mq('phone-wide') {
    bottom: 5px;
  }

  @include mq('phablet') {
    bottom: 5px;
  }

  @include mq('tablet-small') {
    bottom: 5px;
  }

  @include mq('tablet') {
    bottom: 5px;
  }

  @include mq('laptop') {
    top: 65px;
    bottom: auto;
  }

  @include mq('desktop') {
    top: 65px;
    bottom: auto;
  }

  @include mq('desktop-wide') {
    top: 65px;
    bottom: auto;
  }
}
