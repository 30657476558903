@import '../../assets/styles/variables';

.form-group {
  margin-bottom: 16px;

  label {
    font-size: 12px;
    margin-bottom: 4px;
  }
}

h4 {
  font-weight: 700;
}

.form-control {
  font-size: 14px;
}

.checkout-tabs.nav-pills {
  .nav-link {
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;

    &.active {
      background-color: transparent;
      color: $petra !important;
      font-weight: 600;
    }
  }

  .nav-item {
    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }
}

.list-group-item {
  &.active {
    background-color: #fff;
    border-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
}

.text-error {
  color: rgb(225, 78, 78);
}

.disclaimer-div {
  font-size: 10px;
  text-align: end;
  background-color: $gray9;
  padding: 10px;
}
