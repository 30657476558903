@import '../../assets/styles/variables';

.terms-page {
  p {
    margin-bottom: 2.5rem;
  }

  .anchor {
    display: block;
    position: relative;
    top: -120px;
    visibility: hidden;
  }

  a {
    color: $petra;
  }
}