.get-that-look-img-div {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 10px;
  text-align: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
    max-height: 350px;
    object-fit: contain;
  }
}

.get-that-look-desc {
  width: 60%;
}
