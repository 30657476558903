@import "../../../assets/styles/variables";

.sale-tag {
  position: absolute;
  top: 15px;
  left: 30px;
  background-color: #d0765b;
  color: white;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 7px;
  box-shadow: #00000010 3px 3px 10px 5px;
}
