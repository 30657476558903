@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.cart-item {
  display: flex;
  border-bottom: 1px solid #dee2e6;
  padding: 16px 0;
}

.cart-item-imagewrapper {
  width: 120px;

  .product-image {
    width: 120px;
    height: 120px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
}

.cart-item-info {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding-left: 8px;

  @include mq("tablet-lg") {
    flex-direction: row;
  }
}

.cart-item-details,
.cart-item-price,
.cart-item-quantity,
.cart-item-total {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.cart-item-details {
  flex-direction: column;
  max-width: 100%;
  width: 100%;

  @include mq("tablet-lg") {
    justify-content: center;
    max-width: 400px;
    width: 400px;
  }
}

.cart-item-price,
.cart-item-quantity,
.cart-item-total {
  flex: 1 1 auto;
}

.cart-item-price,
.cart-item-quantity,
.cart-item-total {
  p {
    margin: 0;
    font-size: 16px;
  }
}

.cart-item-quantity {
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 12px;

  @include mq("tablet-lg") {
    margin-top: 24px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .remove-item {
    font-size: 11px;
    line-height: 130%;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $obsidian;
  }
}

.cart-item-price,
.cart-item-total {
  @include mq("tablet-lg") {
    justify-content: flex-end;
    align-items: center;
  }
}

.cart-item-title {
  font-size: 20px;
  font-weight: 700;
  font-family: $font-cormorant;
  color: #33332b;

  &:hover {
    color: #33332b;
  }
}

.cart-item-brand {
  font-size: 11px;
  line-height: 130%;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;

  @include mq("tablet-lg") {
    margin: 0;
  }
}

.increment-input.input-group {
  width: 150px;

  .form-control {
    border-radius: 4px;
  }

  .btn-link {
    color: $smoke;
  }
}

.cart-header {
  span {
    font-weight: 600;
    font-size: 12px;
    color: $petra;
  }
}

.cart-breakdown-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 6px 0;

  p,
  h5 {
    margin-bottom: 0;
  }
}

// hide arrows in number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
