.checkout-login-modal {
  .close {
    top: 35px;
    position: absolute;
    right: 35px;
  }

  .modal-header {
    display: block;

    h4 {
      margin-bottom: 0;
    }

  }

  .modal-body {
    padding: 0 2rem 2rem 2rem;

    .register-btn {
      background: none;
      border: none;
      color: $petra;
    }

    .login-divider {
      display: flex;
      line-height: 1em;
      color: $obsidian;
      font-size: 12px;
    }
  
    .login-divider::before, .login-divider::after {
      content: '';
      display: inline-block;
      flex-grow: 1;
      margin-top: 0.5em;
      background: #E0E0E0;
      height: 1px;
      margin-right: 10px;
      margin-left: 10px;
    }

    .login-btn {
      font-weight: 600;
      padding: 10px 0px;
      display: block;
      width: 100%;
    }

    .login-btn:hover {
      filter: brightness(95%);
    }

    .login-btn-logo {
      margin-right: 10px;
    }

    .facebook-btn {
      background-color: #415A94;
    }

    .google-btn {
      background-color: #4285F4;
    }

    .notice {
      font-size: 12px;
      a {
        color: $petra;
    
        &:hover{
          color: $red-desert;
          text-decoration: none;
        }
      }
    }
  }
}