#label,
#voucher {
  font-size: 18px;
}

#loader {
  position: absolute;
  top: 13px;
  right: 25px;
}
