@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.get-that-look-thumbnail {
  text-align: center;
  padding: 10px;
  margin-bottom: 40px;
  min-height: 310px;
  background-color: white;
  margin: 10px;
  border-radius: 10px;

  @include mq('phablet') {
    margin: 5px;
  }

  .get-that-look-image-div {
    display: flex;
    justify-content: center;
  }

  .get-that-look-image {
    width: 420px;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin-bottom: 16px;

    @include mq('phablet') {
      width: 500px;
      height: 280px;
    }
  }

  .product-title {
    font-family: $font-cormorant;
    font-size: 20px;
    color: $obsidian;

    @include mq('phablet') {
      font-size: 18px;
    }
  }

  .product-price {
    font-size: 16px;
    line-height: 170%;
    color: rgba($obsidian, 0.8);
    margin-bottom: 12px;

    @include mq('phablet') {
      font-size: 14px;
    }

  }

  .original-price {
    color: $petra;
    margin-right: 8px;
    text-decoration: line-through;
  }

  .discount {
    color: $petra;
  }

  .product-link {
    &:hover {
      text-decoration: none;
    }
  }

  &:hover {
    border-radius: 6px;
    -webkit-box-shadow: inset 0px 0px 0px 1px $petra;
    -moz-box-shadow: inset 0px 0px 0px 1px $petra;
    box-shadow: inset 0px 0px 0px 1px $petra;
  }
}
