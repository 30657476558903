$font-title: 'Cormorant Garamond', serif;
$font-text: 'Trade Gothic LT Std Cn', sans-serif;
$font-light: 'Blair ITC Light', sans-serif;
$font-bold: 'Blair ITC Bold', sans-serif;
$font-medium: 'Blair ITC Medium', sans-serif;

$font-montserrat: 'Montserrat', sans-serif;
$font-cormorant: 'Cormorant Garamond', serif;
$font-bodoni: 'BodoniStd Bold', serif;

// Colors
$logo-color: #D0765B;
$obsidian: #33332B;
$vulcanite: #43423D;
$smoke: #878787;
$lintel: #A2A29A;
$red-desert: #AC4C3E;
$petra: #D0765B;
$petra-light: #FFF7F4;
$copper: #F38C6C;
$copper-light: #FCF9F6;
$gray: #F5F5F5;
$gray6: #F2F2F2;
$gray9: #F9F9F9;

.red-desert {
    color: $red-desert;
}
.petra {
    color: $petra;
}
.vulcanite {
    color: $vulcanite;
}
.obsidian {
    color: $obsidian !important;
}
.copper {
    color: $copper;
}
.copper-light {
    color: $copper-light;
}
.petra-light {
    color: $petra-light;
}
.smoke {
    color: $smoke;
}
.gray9 {
    color: $gray9;
}
.bg-gray9 {
    background-color: $gray9;
}
.bg-petra {
    background-color: $petra;
}
.bg-smoke {
    background-color: $smoke;
}
.bg-gray6 {
    background-color: $gray6;
}
.bg-gray9 {
    background-color: $gray9;
}

a {
    &.petra {
        &:hover{
            color: $petra;
        }
    }
}